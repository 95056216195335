import React from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import productsData from "../../components/productsData";

const Suggested = () => {
  const { name } = useParams();
  const suggestedProducts = productsData
    .filter((item) => item.name !== name)
    .sort(() => 0.5 - Math.random())
    .slice(0, 5);

  return (
    <Container>
      <h3>Suggested Products</h3>
      <div className="product-list">
        {suggestedProducts.map((suggested) => (
          <div className="item">
            <Link to={`/produktet/${suggested.name}`}>
              <img src={suggested.image} alt="" />
            </Link>
            <div className="details">
              <h3>{suggested.name}</h3>
              {suggested.discountPrice ? (
                <span>
                  <h3>€{suggested.price}</h3>
                  <h2>€{suggested.discountPrice}</h2>
                </span>
              ) : (
                <h2>€{suggested.price}</h2>
              )}
              <Link to={`/produktet/${suggested.name}`}>
                <button>BLEJ TANI</button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 2em 7%;
  width: 100%;
  margin-top: 2em;
  h3 {
    font-size: 1.5em;
    margin-bottom: 1em;
  }
  .product-list {
    display: flex;
    gap: 1em;
    overflow-x: auto;
    padding-bottom: 1em;
    .item {
      width: 30%;
      display: flex;
      flex-direction: column;
      gap: 1em;
      min-width: 250px;
      img {
        margin: auto;
        height: 200px;
        width: 100%;
        object-fit: contain;
        cursor: pointer;
        transition: ease-in-out 0.4s;
        &:hover {
          transform: scale(1.05);
        }
      }
      .details {
        border-left: 1px solid lightgray;
        border-right: 1px solid lightgray;
        text-align: end;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: end;
        padding: 10px 1em 0 1em;
        h3 {
          color: #292929c1;
          font-weight: 400;
          font-size: 18px;
          text-transform: capitalize;
        }
        span {
          display: flex;
          align-items: center;
          gap: 7px;
          h3 {
            color: gray;
            font-size: 16px;
            text-decoration: line-through;
          }
        }
        h2 {
          color: #4b4643;
          font-weight: 500;
          font-size: 18px;
        }
        .quantity {
          display: flex;
          label {
            font-family: 500;
          }
        }
        button {
          font-family: "Montserrat", sans-serif;
          color: #383838;
          padding: 10px 30px;
          background: none;
          border: 1px solid #aaaaaac1;
          cursor: pointer;
          transition: 0.4s;
          &:hover {
            background: #278527c1;
            color: white;
          }
        }
      }
    }
  }
`;

export default Suggested;
